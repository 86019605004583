<template>
  <v-card class="pa-8">
    <v-overlay v-model="settingFilters">
      <v-progress-circular indeterminate size="32" />
    </v-overlay>
    <v-card-title
      v-if="areSettingsEmpty"
      class="error--text d-flex flex-column justify-center align-center"
    >
      <v-icon color="error">mdi-alert</v-icon>
      <span>Você não selecionou nenhum item nas configurações</span>
      <p class="primary--text">
        Clique no botão <v-icon>mdi-cog</v-icon> para configurar os filtros
      </p>
    </v-card-title>
    <validation-observer v-else-if="!settingFilters" v-slot="{ invalid }">
      <v-card-subtitle class="error--text"
        >Todos os campos abaixo são obrigatórios</v-card-subtitle
      >
      <v-card-text>
        <gerenciamento-servico-list-filtro-modal-item
          title="Gate"
          v-if="!empresaAtual.gate"
        >
          <template #items>
            <v-col>
              <validation-provider
                name="gate"
                rules="required"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  v-model="params.gate.value"
                  label="Gate"
                  prepend-icon="mdi-domain"
                  :items="gates"
                  :item-text="(item) => `${item.business_name} - ${item.cnpj}`"
                  item-value="public_id"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </template>
        </gerenciamento-servico-list-filtro-modal-item>
        <gerenciamento-servico-list-filtro-modal-item
          title="Data Do Agendamento"
          v-if="showDataAgendamento"
        >
          <template #items>
            <v-col v-if="params.data_agendamento_inicio.visible">
              <validation-provider
                name="data início"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_agendamento_inicio.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_agendamento_fim.visible">
              <validation-provider
                name="data fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_agendamento_fim.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
          </template>
        </gerenciamento-servico-list-filtro-modal-item>
        <gerenciamento-servico-list-filtro-modal-item
          title="Data Da Triagem"
          v-if="showDataTriagem"
        >
          <template #items>
            <v-col v-if="params.data_triagem_inicio.visible">
              <validation-provider
                name="data início"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_triagem_inicio.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_triagem_fim.visible">
              <validation-provider
                name="data fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_triagem_fim.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
          </template>
        </gerenciamento-servico-list-filtro-modal-item>
        <gerenciamento-servico-list-filtro-modal-item
          title="Data Da Cota"
          v-if="showDataCota"
        >
          <template #items>
            <v-col v-if="params.data_cota_inicio.visible">
              <validation-provider
                name="data início"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_cota_inicio.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_cota_fim.visible">
              <validation-provider
                name="data fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_cota_fim.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
          </template>
        </gerenciamento-servico-list-filtro-modal-item>
        <gerenciamento-servico-list-filtro-modal-item
          title="Data Do Pedido"
          v-if="showDataPedido"
        >
          <template #items>
            <v-col v-if="params.data_pedido_inicio.visible">
              <validation-provider
                name="data início"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_pedido_inicio.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_pedido_fim.visible">
              <validation-provider
                name="data fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_pedido_fim.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
          </template>
        </gerenciamento-servico-list-filtro-modal-item>
        <gerenciamento-servico-list-filtro-modal-item
          title="Data Da Saída Do Patio"
          v-if="showDataSaidaPatio"
        >
          <template #items>
            <v-col v-if="params.data_saida_patio_inicio.visible">
              <validation-provider
                name="data início"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_saida_patio_inicio.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_saida_patio_fim.visible">
              <validation-provider
                name="data fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_saida_patio_fim.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
          </template>
        </gerenciamento-servico-list-filtro-modal-item>
        <gerenciamento-servico-list-filtro-modal-item
          title="Data Da Conclusão"
          v-if="showDataConclusao"
        >
          <template #items>
            <v-col v-if="params.data_conclusao_inicio.visible">
              <validation-provider
                name="data início"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_conclusao_inicio.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_conclusao_fim.visible">
              <validation-provider
                name="data fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_conclusao_fim.value"
                  :errors="errors"
                  v-mask="['##/##/####']"
                />
              </validation-provider>
            </v-col>
          </template>
        </gerenciamento-servico-list-filtro-modal-item>

        <gerenciamento-servico-list-filtro-modal-item
          title="Fornecedor"
          v-if="showFornecedor"
        >
          <template #items>
            <gerenciamento-servico-list-filtro-modal-item-input-fornecedor
              v-if="params.fornecedor.visible"
              v-model="params.fornecedor.value"
              name="fornecedor"
              label="Fornecedor"
              icon="mdi-account-child"
              rules="required"
            />
            <gerenciamento-servico-list-filtro-modal-item-input-produto
              v-if="params.produto.visible"
              v-model="params.produto.value"
              name="produto"
              label="Produto"
              icon="mdi-corn"
              rules="required"
              :fornecedor="selectedFornecedor"
            />
            <gerenciamento-servico-list-filtro-modal-item-input-sub-produto
              v-if="params.sub_produto.visible"
              v-model="params.sub_produto.value"
              name="sub produto"
              label="Sub Produto"
              icon="mdi-corn"
              rules="required"
              :fornecedor="selectedFornecedor"
              :produto="selectedProduto"
            />
          </template>
        </gerenciamento-servico-list-filtro-modal-item>

        <gerenciamento-servico-list-filtro-modal-item
          title="Contratos"
          v-if="showContratos"
        >
          <template #items>
            <gerenciamento-servico-list-filtro-modal-item-input-terminal
              v-if="params.terminal.visible"
              v-model="params.terminal.value"
              name="terminal"
              label="Terminal"
              icon="mdi-mailbox"
              rules="required"
            />

            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.armazem.visible"
              v-model="params.armazem.value"
              name="armazem"
              label="Armazem"
              icon="mdi-cart"
              rules="required"
            />
            <gerenciamento-servico-list-filtro-modal-item-input-cliente
              v-if="params.cliente.visible"
              v-model="params.cliente.value"
              name="cliente"
              label="Cliente"
              icon="mdi-mailbox"
              rules="required"
            />
            <gerenciamento-servico-list-filtro-modal-item-input-transportadora
              v-if="params.transportadora.visible"
              v-model="params.transportadora.value"
              name="transportadora"
              label="Transportadora"
              icon="mdi-bag-carry-on"
              rules="required"
            />
          </template>
        </gerenciamento-servico-list-filtro-modal-item>

        <gerenciamento-servico-list-filtro-modal-item
          title="Região"
          v-if="showRegiao"
        >
          <template #items>
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.regiao.visible"
              v-model="params.regiao.value"
              name="regiao"
              label="Região"
              icon="mdi-home-city"
              rules="required"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.representante.visible"
              v-model="params.representante.value"
              name="representante"
              label="Representante (CPF)"
              icon="mdi-face-agent"
              rules="cpfValido|required"
              mask="###.###.###-##"
            />
          </template>
        </gerenciamento-servico-list-filtro-modal-item>

        <gerenciamento-servico-list-filtro-modal-item
          title="Veículo"
          v-if="showVeiculo"
        >
          <template #items>
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.motorista.visible"
              v-model="params.motorista.value"
              name="motorista"
              label="Motorista (CPF)"
              icon="mdi-card-account-details"
              rules="cpfValido|required"
              mask="###.###.###-##"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.placa_cavalo.visible"
              v-model="params.placa_cavalo.value"
              name="placa cavalo"
              label="Placa Cavalo"
              icon="mdi-truck"
              :rules="{
                min: 3,
                required: true,
              }"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.placa_carreta_1.visible"
              v-model="params.placa_carreta_1.value"
              name="placa carreta"
              label="Placa Carreta 1"
              icon="mdi-truck"
              :rules="{
                min: 3,
                required: true,
              }"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.placa_carreta_2.visible"
              v-model="params.placa_carreta_2.value"
              name="placa carreta"
              label="Placa Carreta 2"
              icon="mdi-truck"
              :rules="{
                min: 3,
                required: true,
              }"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.eixo.visible"
              v-model="params.eixo.value"
              name="eixo"
              label="Eixo"
              icon="mdi-truck"
              :rules="{
                min_value: 1,
                required: true,
              }"
            />
          </template>
        </gerenciamento-servico-list-filtro-modal-item>

        <gerenciamento-servico-list-filtro-modal-item
          title="Public ID"
          v-if="showPublicId"
        >
          <template #items>
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.agendamento__public_id.visible"
              v-model="params.agendamento__public_id.value"
              name="public agendamento"
              label="Public Agendamento"
              icon="mdi-truck"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.veiculo__public_id.visible"
              v-model="params.veiculo__public_id.value"
              name="public veiculo"
              label="Public Veiculo"
              icon="mdi-truck"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.motorista__public_id.visible"
              v-model="params.motorista__public_id.value"
              name="public motorista"
              label="Public Motorista"
              icon="mdi-truck"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.motorista__cnh.visible"
              v-model="params.motorista__cnh.value"
              name="CNH Motorista"
              label="CNH Motorista"
              icon="mdi-truck"
            />
            <gerenciamento-servico-list-filtro-modal-item-input
              v-if="params.chave_nfe.visible"
              v-model="params.chave_nfe.value"
              name="Chave nfe"
              label="Chave nfe"
              icon="mdi-truck"
            />
          </template>
        </gerenciamento-servico-list-filtro-modal-item>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn plain @click="$emit('close')">Cancelar</v-btn>
        <v-btn
          class="px-8"
          x-large
          color="primary"
          @click="search"
          :disabled="areSettingsEmpty || invalid"
          ><v-icon>mdi-database-search</v-icon>
          <span>Buscar</span>
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import ContratoAPI from '@/services/tenants/contratos'

import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'

import ConferenciaListFiltroData from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListFiltroData'
import GerenciamentoServicoListFiltroModalItem from '../GerenciamentoServicoListFiltroModalItem.vue'
import GerenciamentoServicoListFiltroModalItemInput from '../GerenciamentoServicoListFiltroModalItemInput.vue'
import GerenciamentoServicoListFiltroModalItemInputTerminal from '../GerenciamentoServicoListFiltroModalItemInputTerminal.vue'
import GerenciamentoServicoListFiltroModalItemInputTransportadora from '../GerenciamentoServicoListFiltroModalItemInputTransportadora.vue'
import GerenciamentoServicoListFiltroModalItemInputCliente from '../GerenciamentoServicoListFiltroModalItemInputCliente.vue'
import GerenciamentoServicoListFiltroModalItemInputFornecedor from '../GerenciamentoServicoListFiltroModalItemInputFornecedor.vue'
import GerenciamentoServicoListFiltroModalItemInputProduto from '../GerenciamentoServicoListFiltroModalItemInputProduto.vue'
import GerenciamentoServicoListFiltroModalItemInputSubProduto from '../GerenciamentoServicoListFiltroModalItemInputSubProduto.vue'

export default {
  name: 'GerenciamentoServicoListFiltroModal',
  components: {
    ConferenciaListFiltroData,
    GerenciamentoServicoListFiltroModalItem,
    GerenciamentoServicoListFiltroModalItemInput,
    GerenciamentoServicoListFiltroModalItemInputTerminal,
    GerenciamentoServicoListFiltroModalItemInputTransportadora,
    GerenciamentoServicoListFiltroModalItemInputCliente,
    GerenciamentoServicoListFiltroModalItemInputFornecedor,
    GerenciamentoServicoListFiltroModalItemInputProduto,
    GerenciamentoServicoListFiltroModalItemInputSubProduto,
  },
  data,
  computed,
  methods,
  async created() {
    this.settingFilters = true

    this.getParamsFilter()

    if (this.empresaAtual.gate) {
      this.params.gate.value = this.empresaAtual.public_id
      this.params.proprietario.value = ''
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = possible_gates
      this.params.proprietario.value = this.empresaAtual.public_id
    }

    this.addParamsFilter()

    this.UPDATE_PARAMS(this.params)

    await this.showOnlySelectedOptionsAndGetItems()
    this.settingFilters = false
  },
}
</script>
